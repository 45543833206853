import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="col-lg-8 my-5">
      <h2>{`Summary`}</h2>
      <p>{`Esteban was in charge of product concept, product management and product launch.`}</p>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h2>{`1. Research and Strategy`}</h2>
      <p>{`Analyzing the market conditions of Korea, showed large investments into entertainment, big data and AI. Within entertainment the gaming industry had an ongoing problem that kept coming up in many reports: Toxicity. `}</p>
      <p>{`An opportunity to launch a service that would support ongoing efforts by the gaming industry to curb toxicity with the power of voice-based ML and NLP arose.`}</p>
      <p>{`To develop our solution we spoke with companies (from small to market leaders) in the gaming industry through referrals, events and cold contacts. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3cf838e4001baec73fffa91fae486dab/df56e/shush_7.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADkUlEQVQ4y6XUX0xaVxwHcF/2toc97WF7WrIsWZPFZY2m24PTuS1tlq5pu9UtbffPRU2rlqzYLrGUlqKX/66lWAS0Q9EaheFsGRVEgXsvrgpcQCkXsRSVe+ECIhX8B/PeBaxrtXvrL9/knJfP+Z2c5PyKqJeoIrKwLKY2HXjGFVn9L57o/2eaWM1ukU/x9mbYlxTC+O9ITGYn5HZCYScEEA5YMI51VwArxgfx9OY/FEWR+c4FfA9N9iBxjXfpT1/SMJdSOAghjLfZ8hHBzyKEsOs2PLMH6/zL1yciCgfR6Yjd8SQkD6JcEONDGA/EBBAuhHEeHOFDGBfEhTC2t7N+dpltDrfZcAGMc6x5I7MTt50xJRK78SDKA8OAaQ4AMWD0Ec8cSme39uJWSx4LC9fjQ1iPK672Lqm9CaU70W9G74+5W8ZDQiMqMgdfxMlWy+JvNkwE5yOAwt0IMfRwqdtJcCFcrEMshknRPY/Q4BPpvSub5C78V+DJNWtEYIvyYIIHR7lQVDIZ60QSMiQumoozDegl1eiVEZRtmedYFzLPdc7rMd+i2OTphP1yEJXDswp4VgHNto/7WzQ+QOtnqx9eHphp0aJsDcrRoun1XB6TVBFF5o+J/D3g76N7epmPR8RuFTA3LElNdLh72+s/FjdU3KR9equhvK3poOyXz6X0Q7JUPFPAZBG5jeE+j7xm9HIV0nEOVvJntB0x+4BLqwRqu6+dUtJPMZp+qq0tozd+1E4rlzyHt/L4kVGm/7VyiFllHwTUqqtucGgBHJzSqNrO3+HW9jefrEFUV85+dqT+wE36J9IXsL5rjHHSImO77t7qlZ82/NE4p6E5u5iNFZLvD7DqKssCUhbz6y++K2acL+9KxdNPMbX9Yq7AZKtIx2LlTKYt3Zv3m18Zb3ltcfDdmvdFjSVl37736vyNY9UfvgF8Wc461JGIZnZh0jxtOEuTN5wOKZU5zdt3m0s8PV8FBoolR+uhM2+ZaMXU7aPD1fuVP78z0nQRW9h4himKWtE7jGKpUS7HND1hPeC0TeEB34yOQy8901VVqvthn5tOm2RU9VeXSL4BotjqDi78yVifKQx7g65gUtPbXVchvVBv5NO1FyrrPjgnO1GmPr5v6sTr4z+WTlw8wj/MIPC1HVyoXCK1kVpbW8tml+K435leTq6vPIkH3fP+8HIosBGapjDn+mMkvRCIBrFcdmcYvMwY+hdz8qaU/hHqygAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/3cf838e4001baec73fffa91fae486dab/5a190/shush_7.png",
                "srcSet": ["/static/3cf838e4001baec73fffa91fae486dab/772e8/shush_7.png 200w", "/static/3cf838e4001baec73fffa91fae486dab/e17e5/shush_7.png 400w", "/static/3cf838e4001baec73fffa91fae486dab/5a190/shush_7.png 800w", "/static/3cf838e4001baec73fffa91fae486dab/df56e/shush_7.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d23f6b71672dce95e0e2d44747cd5d02/df56e/shush_8.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADK0lEQVQ4y6WUSUwTURjH9ehRD0riRb1o9KKJFxO96MmoMUbFGJQgNmqVNVIUXMBdSRDRgEsVXAAjlFWQoCFKNKAikCItte1MS2e6DrN0Zpilnfee6WhZb/rlJe+95Pt93/v++b63CP2HLUIwsTE+hRgWSKtIWkW/VfSP6uvnVMAuhVxKxBOjSDAZhHQIMmEIQBKG+ul7fbje6GopwJvy3I25WFM+1lSAN5/3dlz1d1dQvU/5/nY4+B4NdIL+Lk2VdRQm4UFL5E0h/qGCfHebaL3ia79Gvr0Z7C6f7LrH9VRPdT4Rn92yVl3s7Wqgf7xHqgznZO63RGpN7pZyX/Nd0lIZaCwPWsqY1gdic9VU93N4v4Jcsnr3qsVrirbmjfShmAym4USYnvrgDYOtLNtx3fir1OguzfKeO++9U8JV35Nra2KvW9CWS6MXUtK7898OfUOqpP2FgQ6/MU+cSR05d3yswOA4exIryPYfzB3LzBmqfgFfWbQXfSj1ymDalvz0tEcNVRiI6xXDJPzysffYwZEcg/2MwXHa6DmVRZ4uljcbPp649MXUhra1Ta2rZbcfur5iQ2bRngpdbQCn4RozcXj/yPEMW3rG+FEDfvgkcSBvckexmLKrcuWRmuWmT0urqWV7K9en7DOl1el6zYKfPsRPZFpNha5cE5ZdOGEs8meWUBnlquF+4LLZmdfIrjXTm7Je79y4v7TMKk1n/iNY3e3egR5nKCBidgp30B4X43bQmItxO6MEqUZVFBRhmIri4wRNyzMdBmEC7jT3e8cjCR0SV/i3CSBEUEMQTHuDOIAamNWeuqvGc8A7ljhoms9H4LgHw3Cn00WSfkmS5Zgy/PmD65c9GX0G1k2LQZEDEGoakCRJFEVBEGiGRghxlN/psE18f+cbbNIftACGCMVUSeB5QRB4nmcZhuejkVDE9tVLeCfsA31RS2q4PU131RbAEEgCx7IMxzIcx9I0zXFMKBDuePIl4mfZSSJk6xECtmSiec/Wi1F1UxRFlmVFUeJaopUURXHjHiJAUTTL8/zceZ4xuHDctTiAAMbjMRBXAYjPVmse/A8/yX/AvwExYQTmC1ClAwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/d23f6b71672dce95e0e2d44747cd5d02/5a190/shush_8.png",
                "srcSet": ["/static/d23f6b71672dce95e0e2d44747cd5d02/772e8/shush_8.png 200w", "/static/d23f6b71672dce95e0e2d44747cd5d02/e17e5/shush_8.png 400w", "/static/d23f6b71672dce95e0e2d44747cd5d02/5a190/shush_8.png 800w", "/static/d23f6b71672dce95e0e2d44747cd5d02/df56e/shush_8.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/589f6b269524819b5b3f47ea51235cfe/df56e/shush_9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC7ElEQVQ4y6WTTUzTYBjHORm9cJcAJhq/MOoJMXwYgUiAYRCCAdQLwRgzBMEY8MBRb/gVQiLZTOAEDpgwhsQAAmNRBkIg4GTsg7GPrnOja/u2Xbt+vGYbYBkHTfynaXt4f/+nz/N/mgT3JB0U/AclwdixbTMapjgoc4KHDERRFAThABw/o+002NZdJMBDGAYAoMPhuEfs2vXBMMxqsYmCJIejD9QdtFkdbo9302Yf/jjY09UR8CMhUgru4CSBk1EBgiBDoRDDMLLPjonl2MWlZb1uVKVS1VSVFF5OX1v5blxyVFXX36mtr6i8rSgrL79VeT3/xstXbyCEgiDK4cjEZ31729PmpkcDHwaetzZsbax+NS2kpaZlZ+edPZdxPOVEavqpo8eSlcrGGCz8gZkwa5qbmpyYmDUYV0zzhqlJxGklCGpMPzE7bTDOzpkWFudNizOGObP55/4UduFIJOJ0eT0IiqKo1Wbz+/2iKLJhLuDHAn4s+CsEAKAoiqZpeQhJe9GIGxbL6trapsXidDpJkhRFEQCwabVubzvtdofNbvd6EZ8P5SKRRBiEKLcH8SAIjuMkADRNx1PdCe5gGMYwDMuy4XCY5ThRkg7nPOvc8PJiJJoJAARB4DgOAOB5Xr4VQkTgWT5xw7bWfUF/iGZoktiFKYpCUbS/Xzsy8kmnGx/WjU/NGSdnjDaHM3Fg8NAmQgjtDseFi5e63nUWFyuSU9IftLVdVSjeqtUQQl4elSRJDMNQFBUvS5IAQoi48ZtXelvufmm6p1FkPSzMLao5mbPcPRB1l8MQQhzH3W53IBCIdUtDKPldsKPuW2vZdGM2VOZqNMr24GklfK2HsdIHYJ7nOY6LNyOJ0TviAQ11z1ru11Tkvyi/9rj5TM6TI+cnO3uilRNg+Y8d79nl82bVVpeWlhWV5OcVFWRkZWYW5HW/Vyeu5z4mf6EoWj86NjQ4qB3SDWtHNH19vSq1ef3H/kST4H/oL7B4SPLd/g1oJQ9vfRfIxwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/589f6b269524819b5b3f47ea51235cfe/5a190/shush_9.png",
                "srcSet": ["/static/589f6b269524819b5b3f47ea51235cfe/772e8/shush_9.png 200w", "/static/589f6b269524819b5b3f47ea51235cfe/e17e5/shush_9.png 400w", "/static/589f6b269524819b5b3f47ea51235cfe/5a190/shush_9.png 800w", "/static/589f6b269524819b5b3f47ea51235cfe/df56e/shush_9.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h3>{`2. Product Design`}</h3>
      <p>{`Along with a team comprised of NLU and ML engineers and using existing open source technology we created: Shush CM. `}</p>
      <p>{`Shush curbs abuse in communications by detecting language that can cause harm. It aims to increase diversity online and protect minorities, women and kids. `}</p>
      <p>{`Esteban project managed the dev team while the solution went through various stages: `}</p>
      <div className="table">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Idea`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Development`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Staging`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Launch`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Concept`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Demo, Test case (local), test case (cloud)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`API services + front-end`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`alpha`}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>{`Integrations`}</h4>
      <p>{`Shush CM has a discord integration that sends information to the API. `}</p>
      <p>{`The meta data from the recording is displayed in a JAMStack front-end which I developed using reactJS.`}</p>
      <p>{`Esteban also developed two integrations for Zendesk: `}</p>
      <ul>
        <li parentName="ul">{`As an API call from the front-end using JSON`}</li>
        <li parentName="ul">{`As a packaged Zendesk app using Javascript`}</li>
      </ul>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d7d03d53c8fdbac2a7fc7101c0313a78/df56e/shush_28.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB+klEQVQ4y82UWZLbIBCGff8TZNMFfIc8JBVnyp6x41XyeNFos4wW0IKRAIkmJdvjOHlLpVLJXzxQXf3RDfzQ03+g3n8JA1zH36kMAG2rlAJ1ry6ipGx53QjenENwl9MhcIY1Y8xxHM8Pmqa5Nay13m/CLx8XG/Nw3zohxHEcQggAdJU5F+Eh8OxtksSYEFnXWgjO6zhKayZpybIsLzJMjg4hGCHEGOOcX2HGZRlumkU/jzxMGR6Pcb+Po5hkRGtNKTVNc7/fe56XJImUUmvdtX6Fa0ncJRu+y44vhFVoMIgMA0cRJgQUFHlhWZZt23EcY0yEEL/AInUWbGQk/i7KCjQYhIZx9P04SUCpuuYIHdMkCYKgLAsu6vZywhf4VAviLqvRB3LYpZTGD18PhoECP0lTrXUSx5Nv08V6Z9sv27Vrzm2SFt2hqgtcidydnx7e+1vbR/nx0+fw7ZsUoRRjAKBluViuhlPT9wNnG1nzII3LV1hBLSQjIdk8Tob7ychGq0359EiLglKqARhjCKHU93OMT9WJC/5jz22rtNZpsA2fx21NtdbxbnecThvGLhebF/lyuZo9zdeWNZvNXdcTQsBZvc5OrWokl5wp1QBAw7lkrG2anyx39lxRFFmWVVV1rXz200W3SWcouNNtiVf/XVJ+82HcsH/9GXwHiSp/6iZA0TgAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/d7d03d53c8fdbac2a7fc7101c0313a78/5a190/shush_28.png",
                "srcSet": ["/static/d7d03d53c8fdbac2a7fc7101c0313a78/772e8/shush_28.png 200w", "/static/d7d03d53c8fdbac2a7fc7101c0313a78/e17e5/shush_28.png 400w", "/static/d7d03d53c8fdbac2a7fc7101c0313a78/5a190/shush_28.png 800w", "/static/d7d03d53c8fdbac2a7fc7101c0313a78/df56e/shush_28.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/df56e/shush_26.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACw0lEQVQ4y22U226jMBCG8+B9h16013mCqvd9glaNegASaIyxweDzAYc02zRAuwpus9XufkKWsfTzz4xnmH3+w8fE+M3Pw7AZxzHsZ/+K+74vy1Jrba2llK5WqyzLAACU0jiOGWPe+/V6/fj4OHt9fTXGbLfbk/hwOEgpnXPWWs65EEJKGVattZuAEAIAZm3bcs699yGkz8/PYRgAAM/PzxhjKSWEcL1eI4Qwxre3t1EUWWsxxkVRHMPuuk5KGZQhbM45Y+xkGFZCSJqmAAApJWOMcz4bx7GqKiFE13XDMJyckyQhhCil8gmMcVmWi8Xi4eFBa40QyvN8NgwDIUQIUdd1EPd9TynlnGutxQ+qqloul2masokv57IshRDhnRDy9vaW5/lyudRaK6UghHmeVxOLxeLu7g4hVJYlhPDobIyx1rZtu9ls2rbd7/ec86ZpgmHIP3w3TdMsy4QQlFLG2FHsvVdKrddrrXXIuSiKNE0JIVrroigghISQsizv7+/jOLbWlmX5Ve3dbue9DwmHnAkhVVUxxoJJ8CGEJEkCAFBKNU1DKT2KlVKMsZM4OGdZVte1ECI4Y4wppQghCKFSihCCEJr1fW+tlVIihIwxp6tK01QpxTmHE+GqoijK81xrnef5V8FCVYUQoc8Oh0NVVYQQPkEpreuaMRZyBgDsdrvtdiul/FMwxljXdfv9PlxvOxE62X+z2Wxubm6urq6ur6+/nBFCjLF6gnMeNAihp6en0I9RFBVF4b03xszn8/Pz84uLiyRJZh8fH92Ec65t23DnxhjG2MvLS5IkTdPoCe+9c+7y8vLs7Gw+nx/D/muYx3Fs2zZ0SF3XVVVxzo0xQW+MwRhDCIuiOLbnf38Gp8GYZqAAAISRXCwWcRw75zDGx3nev7//fN7fD7u3X1oba521bsrCWWucayllq1WKMQ7nzrnfTBU/5u6S0bwAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/5a190/shush_26.png",
                "srcSet": ["/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/772e8/shush_26.png 200w", "/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/e17e5/shush_26.png 400w", "/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/5a190/shush_26.png 800w", "/static/cfd6b4f8d468f537c7a92c0b9e4f5eb1/df56e/shush_26.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dbf227144d75f2b6e238c00df1d4bc51/df56e/shush_25.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADS0lEQVQ4y5WTy24bNxSG5+msFHmBbgsU7aIwim6C9LJJmtRNakey5JvUGJKL+AUU27KQXeMIsS3FF1mx6tiKpLlIcyVnOCTnQg6LkVLDzSr5cRYHh+c/+HgISplMZuZzlMnM3Lqd+eL2rZnMjEQo+YwICEL+WDZHQxMhX/Kx/+mBCYbAff9WuTqTXdeTkI+m4WN/mnjIu658FJMiohGhEfExkhBCnue5LoQu9Lz/bL7veR6cyP2/Js3TzJUAhJoBddsjNHBsR1M1VVUHg6EDAOc8juPohjDGUx9CSNM0CQCgGxbClHGh67phmoRSTAhjzPd9SgNxQ5zzKI7iiYaynJoN0wKuF7PEdhzDMhnjlFAhBACg3x8ghOI4FkIkSRJHIedsOkiWZQlCqKgj24ExSzBGELoYkyAIOOcsZpgQEgYsSXiSMJ74/QOidliSjlJkRXIAME3bgW4QxTbEnofCMEqS9Fy2xv1eT77smZqmXF5GXIRWnygnMZ+YFSXFHuuGbthhFEMcIoJpFIRhKISAIR4bjutgAAiGZErOOU+usQEApuWYlhPzpD8w3/8zsDTruiPkgjJBozigCLt0SiRumm0b+Om2k+E77bTxttcZcpaSMcYZNUU4EpESwzY2zpLIFIkvBPtgdhxnKCuqOg4jNjI1fdShWOU8Th+ZJ8blG/XohXmxN+r8bXRf+uoB6DXgsJVwlt7ZxziK+RSHhS5FShRY12whgiEY+4ZG7FEADFfXgDaEI4Vznppf7u3tHxweHLaazTf7+4ev95uvXr1uNBqtVuvk5OS03T4+PTvrnHfOu8enbULpdG0fsHO53Orq6tpasVyuzC88+f3R44X5hUK+kM1ml5aWVtfWHj789cH9n+cf/3bv3v3e1ZXgzHHJyMKqIkuVSrlcLlcqG+XyRunPp6XS05WVlfX19VKpVCyVNjb++uPRgx+///KXuz/cuXP36OjYhU63bzTPNX2kSvl8fnl5uVgsZZ/kCvn84mJ+bm4uny8UlgrFYjG3uFjIZn/65tvZr77+bna22Wzajn1x8a7dbquqKu3u1ur1eq22W31e3dnZ2ZqoVkuL9Xq9Wq1ubW8/39refLa5ufms2+1qWvrn+v2BYRj/Apvt/qjqTUKTAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/dbf227144d75f2b6e238c00df1d4bc51/5a190/shush_25.png",
                "srcSet": ["/static/dbf227144d75f2b6e238c00df1d4bc51/772e8/shush_25.png 200w", "/static/dbf227144d75f2b6e238c00df1d4bc51/e17e5/shush_25.png 400w", "/static/dbf227144d75f2b6e238c00df1d4bc51/5a190/shush_25.png 800w", "/static/dbf227144d75f2b6e238c00df1d4bc51/df56e/shush_25.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f30c89b184015a4e9a6f29f767e894a4/df56e/shush_3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABD0lEQVQ4y61Uy07DMBDM//8FH8CdIxcEXECUtrc2lUgESkvseG3v2ut1EOUGCU0Kc1xpNDP7Kvo/oBis5pzPJ5+vLElWtyWANWC99wDAzDOUyQdCb4xW7TuYLqU02zbHWFWVs3Z2ZudcPGLM8zA5JVnd7ZRSzKy1btt2njKHFCiICBER4tjkhru9WdTokSVeXdxfXz5+Tl7yNOXcg/KRUgzcVOrw2nFMHNNU27bDl7Lebko+AowD7fo+f2vekG3Jzzfbp4fFcr201iFis2/UoeMYAtFpZZHsANEG8tEDgXaE8efOD5Cz5H2tjTHOOQo0c7cl79ZvFmzXaQA456pE5ORhjmb+Svg7v/j/TzKR/AHTpJ6YcuIm4wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/f30c89b184015a4e9a6f29f767e894a4/5a190/shush_3.png",
                "srcSet": ["/static/f30c89b184015a4e9a6f29f767e894a4/772e8/shush_3.png 200w", "/static/f30c89b184015a4e9a6f29f767e894a4/e17e5/shush_3.png 400w", "/static/f30c89b184015a4e9a6f29f767e894a4/5a190/shush_3.png 800w", "/static/f30c89b184015a4e9a6f29f767e894a4/df56e/shush_3.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2477502809fe43185007442894b7464e/df56e/shush_5.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA3ElEQVQ4y9WTS08DMQyE+/9/IIsQFPUA22wh+4jzqO1x0KoX1Adsi5DA8iGHfJmZWF7VH9Tq78FmdnT4ClaAmT9fhcJgi5RZNedshvkh1ZRSGEOYQi75kvh52wC8985t27YdhuE6+BBWRFVxy4cBlslnGhVWFyqbmaqq7FP/EncPc0+eRUUEwPewKErsyTVj9zS4degeS06L4EOVFKm7H7u1d8/BNTkGW5h5Nr1n6l/JNWF7R2+bUgoznyY/r2y1imgK72naMQuuH1VlUZYbRwWiSESz4f+zkr8OfwCYd6NbnsVPRAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/2477502809fe43185007442894b7464e/5a190/shush_5.png",
                "srcSet": ["/static/2477502809fe43185007442894b7464e/772e8/shush_5.png 200w", "/static/2477502809fe43185007442894b7464e/e17e5/shush_5.png 400w", "/static/2477502809fe43185007442894b7464e/5a190/shush_5.png 800w", "/static/2477502809fe43185007442894b7464e/df56e/shush_5.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4ebaaf5d6d048aef12a062af9ebdab12/df56e/shush_27.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFUlEQVQ4y8WUS09TQRTH75dwKeBOE4l8Ek1MCrioiHwEFy40LYmtsnXjY6EWF0YFNSFVIpgUEmg0xbbWqhBrKqH0Pnrb9HHvnbkzc+aY217akm40XfjLbGZy/nMec84oOATKUGIhBOeCMwEg/0kppRzOc/bTj63V7OarTKlgICI/QghvdWGM8eMQQpTQlQcz524FTobiT7aIa9Vrjc6tjApGRTdC4hAppQRghErgiGiaphINPr169k5gJPTh+Y5EsJq2EKIjaBsDF9AthwC0CTLAelUc7GtKdGZxbmJhajT8Nrb9ZS9vmlXPVvrWtmU1KxpxHNd1OQfBWo3SaqO8Vki93svllEgwNjexEBgJJV6mvbtdihKQs6PgCTUOnVaTUoqIQDVevFnPXnNXLpjFlBIJLs6O354aC7+5t0ltKoVAAE/fRtONA1XTK5WyqmuGQShrUgm2a95fUn8W/bCnT80v391o1Zx2tn6REDGTzy+/eJdY2VF1XdV0AEAUEphfsEgwNjvuFSyxlPHC5tD/kpzzbv797eGL56cfBU9HejmLY2JKXcdxiONYLZtzPiC+9PjymejkaHhj0LOUApEeqmZ8/WP8a920Okn1xDcuPpwcC50/cX39WQoRXcrEIH7P9XbJeD69/U35/rmQXEsn36d/7f6u1aqGYVQGMT36D3ZzhdJ+efiRZJ2RBPnXgGcOyn/7Sf4As99O1kFytDEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/4ebaaf5d6d048aef12a062af9ebdab12/5a190/shush_27.png",
                "srcSet": ["/static/4ebaaf5d6d048aef12a062af9ebdab12/772e8/shush_27.png 200w", "/static/4ebaaf5d6d048aef12a062af9ebdab12/e17e5/shush_27.png 400w", "/static/4ebaaf5d6d048aef12a062af9ebdab12/5a190/shush_27.png 800w", "/static/4ebaaf5d6d048aef12a062af9ebdab12/df56e/shush_27.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h3>{`3. Launch`}</h3>
      <p>{`As a startup with only months of existence:`}</p>
      <ul>
        <li parentName="ul">{`We obtained the preStartup Package from Hanyang University `}</li>
        <li parentName="ul">{`We participated in the Global Acceleration Program that paired us with one of Beijing's leading accelerator: Innoway`}</li>
        <li parentName="ul">{`We participated in the K-Startup Program 2021 (<3% selection rate)`}</li>
        <li parentName="ul">{`We also participated in other programs: Next Unicorn, SNU's Herero, etc.`}</li>
      </ul>
      <h4>{`Other tasks`}</h4>
      <p>{`Esteban participated in various programs (including pitching and networking), prepared the presentations, designed the `}<em parentName="p">{`brand`}</em>{`, other communications (website and blog), and promotion through online and offline events. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fa77e5a20c5036925aa125656fa28d0e/df56e/shush_10.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAClUlEQVQ4y8VU7U9SURzmX+lf6MW1vtRyTWvTZab4odKlzWbYnMaSTZGclK1QC0NRkmwXUjNMLVOnqeEbhcsyR+DkigpD7kWELi/n3HvuaXCxbH7Ltp4PZzvn/J7f83t+50WEDwDRQckcRAdS5hP4tZqc7o44scXvAU7EiiDgzH1fIeD2J+YxRntT7ldmgtH6fMLvo71eD8OEBYVYjAVRwKPfdlACQhEh+geIwTiZY9Hmis+xQdM7IYwxgHDa+nl8xrpEujwDxl6FklA/n1LrkN3Z1dX9pFHVrtH0jwyRq3bM73oenlte3fCxLA6FI939fe8GTDYXadK2Zp3KaCi7Y9UQHyanVQ+VNaUFndqGdl2T0UCAWCxO5hFec9g8m5v+UDjMhCfG3n+cHLeRztnBN8ab0sU2g+P1qEwqr7lVnn7iiEKhuHo5Pze3EEJWJHTEHwiQLtcWTQMACb2uvlqm07WY9S0maXnH49aq4uK8TPGN62WXso/LayuvFElOp2ZFokAkNNPtDzvcQac3bvtebXXOuTSp5JpSdjflUGrK4TNH086mZOQolXVVFYUX88StmuYeohMAkPS8SMHRVYakoxjjwE6AoigGMJ9GnHrJmLZ54ZWPVq9sDM6b1fLyAvGFLzPDnu9mhLiksmsnbN/ajgCAMQ6GmAiAkcB2kKZ4xPd2PLWSdgvGU98Wlt92zPdrHQsjazYLQiipXC2vKS0pEQ7Z66Uo2r+25NTfJ26LlZUZRSqjSmbqkVRU5GWmpZ88lpt9fnZiCHGcSCC43e719XUhEceyEEKE8JzFYnhh6Ot9Sei16gd1TY3NGu2zlrbOR+q2abP5X7wq4fZx3B/Xm+d5juPYOGCiFAD3QAgW/dfP4K/JPwH9uRsGDUX4TgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/fa77e5a20c5036925aa125656fa28d0e/5a190/shush_10.png",
                "srcSet": ["/static/fa77e5a20c5036925aa125656fa28d0e/772e8/shush_10.png 200w", "/static/fa77e5a20c5036925aa125656fa28d0e/e17e5/shush_10.png 400w", "/static/fa77e5a20c5036925aa125656fa28d0e/5a190/shush_10.png 800w", "/static/fa77e5a20c5036925aa125656fa28d0e/df56e/shush_10.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b915e2907fdf795718d1f1b308f77f87/df56e/shush_11.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmklEQVQ4y8VTy3LTQBDUp/I55js4QcUUPwAhxBco50AcMBBiIUuKvZYirR6rndnd2UlJIikTKArKh/SpD9vTM9M7AR+A4FCxM3SQs7/D/4kNutOX5xbdXpXfid8nfGcRdA08ffIsidLNdiPE7l8Ma9k625sF5HwcSkCDCF2nZaZ0i+RIZq1u0Q0EFDrrZNZAZ6xxmyhr626c2bPaelDMDB0eT+fLeahqOD6af/8YVzft6+cfVhdpXag3L+bRclPlavbqPLncDWJPdPHOS8HMiBjH6/wmZ2YNmoiMtcl1qnTvs/zyuSgLZiZPf8i5rqskToQQbduuo3UlK61U+O1rEkWIeL9RaywR/RR7onGNaF0hm1W8A4DVj7AsilzBLMw+pWWSpJPJ5PTtSe9M9DBnZo5EPXt/Fl5d9jxvNFogjhpXIJelnE6ni8Xi/vGwbc8z0ey0GfNTSlnbdyXEtmkack6kiQXwzLIsAcB7r7V2bojKM5dokfxe+D2MMTSMozptnRvrGmPGtkfzgw/D/+r5AH/58MGj3vPjiG8BIiiPdxYmaH4AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/b915e2907fdf795718d1f1b308f77f87/5a190/shush_11.png",
                "srcSet": ["/static/b915e2907fdf795718d1f1b308f77f87/772e8/shush_11.png 200w", "/static/b915e2907fdf795718d1f1b308f77f87/e17e5/shush_11.png 400w", "/static/b915e2907fdf795718d1f1b308f77f87/5a190/shush_11.png 800w", "/static/b915e2907fdf795718d1f1b308f77f87/df56e/shush_11.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/020c8406cd08f3931ee47fbb87e06d8a/df56e/shush_21.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEz0lEQVQ4ywHEBDv7AKrZ+K/r/rr5/sv9/M37/Mbx+rLe96fS9JCz0n+Nn5GcrYWbr5WruZuyvqW6xq7F16/P57bb8Lnm+LLz/QCi0vWq5P7L8/vN9vvW9frN7Peu2/qNttp6ZFuMVTFbWWF7lrGPpraYs8SWts2yy9621OjD3u++5/mp6/0Amszxntr8peT9qeX6p+L6n9n6ndT9fZi1p2NA3YhPoHpkkK7Dj63AkbHGkLTRjrjZkMTqk9L5ldf6oOP8AJfG7ZjQ9pzY+6Hh/5/d/pfP9JLH8Jqpwr9yVM53R8iVeIynvoqqwo2ux42xzo263YafsomWoY7F5pzd/ACRv+iVy/SQyO5+l6uDnrOQxu+Kved8s+OmenfEXzGXj5OArM2IqMGLq8WNtdV5lKuSYUDVl2FzdXmS0vUAh7jkkcz8V3CLkFMwnmQ+Z4uwm8PniJepfFJJtFctqnxdkJ+xgKfIgKfGg7HVXl1lq2Q44ZZhhV1KebLYAJzD44nC8FFJUK1aML9xQGZbXqCTk4FmXS4eGkwsG3hPN614WaSLgImowX+pylpDPK5eOMxyRnpKMneiwgDy6sqp0O4+O0SkSSisVjM9JRuAaGFqUkkuJCIrHxpmRDObZUeSUzGUlJx3k6tWLRuAOCCcTCtcQzWGoLgA/+irvMPBNC81czMYhT4gMCEagGRaYUY8NCgjOyojcEo2lFw/eUAlgWhhVUpMQhwNt2s4eEgtYUAug3RwALWZcWBVTzEfGXFILcaUYkUrHoFfUVY6MC4hGy8gGnRLNo9YO3VBKXhHLkglFmNDLdOhbFI6MGZAKmtCKABTNyovHRc6IhlgOSTDl2lNLBt0SjhPMCI7Jx04Ixl2STWGUDVwOyV7RSpKIBCDZEeNdlxDLCVsQSlTLxwAUDUoKBQPMxsUQyIWtYtgXTwoZTopTS0foIJllHVcb0IsfUYtajcjekMpSSIUYEAtTzMlOSEZRSIVQSMXAE0yJCUTDigVEEIoHNymdpxqSFkvH1IyIePCmcSkgF8zIXxFK2s4I31FKkEkF0MpHUAnHDcgFygYETwlGgA4IhkkEw0kEw07IxjZoG+3hltcLx5KKht4WkBuUThZMCF7QyhuOySBRyxCJhgxHhY4Ihk6IxgpGhE9JhwAKhoVIxQOJBMOOiEXyZ1xmnZWXS0ePiMXGAwGGw4IVy8he0Iocz0lhEcrQiQYJxcROiMYOCIXMh8WQSkeAGEyH4dFJCcWECoTDbaKaL+TcFsrHj8jFiUWECYWEVMsHnxCKXA8JIZJLUIlGCsZEzsjGDchFzcjGT0lGgBeLx6pViswGxIoEg18Szp1TTleLR86HxUmFRAlFRBXLiB7QCqLTzCGRys9IhcuGxQ7Ixg3IRc7JRxGLiQAPSAXoVQsOCAUJxUQIQ0JHAwJXS0gNxwUJxUQIhINWzAgez4pyXRGjkspKhkSNh8WPyUZMh4WeEUqajkiACsWEnlAJFYvGhoNCR0PCyYVD1srHTAYECcVDhoLCFUsHIBBKYA9J2IwHjYgFj0hFz0iFkwsHtuIU208IAA5Ih+sXD2fWjUmGBUtHBgyHhlhMyc1Hxk0IRkrHiNZNCqJTjRnNyg6KCMxIBk+Jh83IRtpPS3afE9cOi+c/dqYKxUDTQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/020c8406cd08f3931ee47fbb87e06d8a/5a190/shush_21.png",
                "srcSet": ["/static/020c8406cd08f3931ee47fbb87e06d8a/772e8/shush_21.png 200w", "/static/020c8406cd08f3931ee47fbb87e06d8a/e17e5/shush_21.png 400w", "/static/020c8406cd08f3931ee47fbb87e06d8a/5a190/shush_21.png 800w", "/static/020c8406cd08f3931ee47fbb87e06d8a/df56e/shush_21.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ca3d1090e0a53ffa4958dec3a85a818e/df56e/shush_24.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEpElEQVQ4yyXNaVMaBwCA4f0v7XglihyKCIEgiqiAoAKKYDQmMTElFa94BBXkRk4REEEFPKJpbTKTpGk/tInIDcuyy3Jq06R/pNN05vn6zgtcxeORRCKeBuMpMAlCaQjOQEgyA4FwHq3cRFOpq3gimkiGY/HLSOQqHgvHouFYNJZMxFJJIAVmkyAIwnAuX4AQNIeWYLSYyxfRUgUtV9NQLp7OpLIQiCBZBIHySBaBswicQ/O5Qh64jETC0Wg8mYqn0slM9tsZToO5QrlaqFSTYDaRSsdS6XA0FonHo8nEfxLxT5FwBoaAdBYCYTgLI0ihmC+W88VKoVxFv5WVvz4Xq9fFahUtl3N5FCkUkFIRKRbRcgmtlArXFQAuFfKlUqFSLV1f33z5ev33l9LN55uv/xSr17k8UiiXYRQFISgDQbk8DOZAEALBHAShMIjkgMM3PofXrlZv6gxanVFnsZiChz673eKwWS7OTs6OD1w723q9xma3mq3mUHD/8GAvEPAdBn2B4B5gCZheaFcWZmfOHPq3oV2fy7rrcspkPyjXV/xe1/zcjM1mMW0ZFEtz88+eqDSqwMFuKODb87m8e05gySFfVMl1ywvvHZrLkMdk0O7teYOH/tPTI83ayrnLpFeubmyqrMql6MnOh1N/wO/eP9w7Pz8+ONgFPD87dQ61ann+1LD2wb/j9riMJqPRoPV4nNtqReZ16Ndj3+nL412z5urYg/x25nbZTWaD2+10e7YBc9C4rF5y6ZWBrY0j77baaJiUDIsFvEePH3kd5ld2wx9vzsKxSHB/9yePGXp/dPHqaMts9O/7bNsWYFGjGH/0dFb2Y2jHLp+Zk45KfnFo/jzZNW2urSuV5lX5u6D747sLs1GrWF7wW7Wz83PTsmdqvc7p3gZoTM5zhUpjsA3ypXyOkM9hvzStZV+HNKuLvex+kZC/LlsP6SziEdGQUCwaHZOOT44/eHjv/uTaxgtgbER85HVvm50Bp99msvf0clanJO+9BrFIwGT1sVgsw+yGWq5g9/NHRqQS6cTYxAPJvfGxiYnnS4vAhVVjX1sRDkhmpuU9bA6RTBkbHvRaNAMDvA4Gk83maGYmlfLH3X39QwKRQCgeHpWKxGLB8Ihs5hkwyue0UzvpXb0kCrWZQCC1k4YFA0QSCUsgkO9Q6J2djM4u9ezUqHiYyerpHxjkCYS8oSGBcPCp7Akw+uDhrSYMntiKJ7aSqBStXg0lPl1+/P30/ITL45DIbVT6XcW01LYxS+/q7ONyuXw+s4+9o5o/tCqBZaWyprYGi8fdbmqk0akW65Z+c12v3tgyaVk93S2tBAqNzOygWNYXpPckHV1dfVxuD5v9zqM+d+sB6eRkXV3d7aZb/6ut/Q7bVN/cVN9Q/z0Wh8HiMYQWbDuFyOPznkxP0RkMBrOrs7vT8kI2dX8EYPcxa2pr6+tr6+pqGhrqbjfWN2EasLhGLK4Rg2m4046nUloIrdg71HbJmJhGo9LoNDaXLRAMcPg8QDDIbm5uxuEwOBwGj8e2tGCJRDyZTGxrw5NJLW/dqhPLKoncxuxmdLMY9LuUuzQyl9M7Jh0ZEgn/BSn8dO0IurCAAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/ca3d1090e0a53ffa4958dec3a85a818e/5a190/shush_24.png",
                "srcSet": ["/static/ca3d1090e0a53ffa4958dec3a85a818e/772e8/shush_24.png 200w", "/static/ca3d1090e0a53ffa4958dec3a85a818e/e17e5/shush_24.png 400w", "/static/ca3d1090e0a53ffa4958dec3a85a818e/5a190/shush_24.png 800w", "/static/ca3d1090e0a53ffa4958dec3a85a818e/df56e/shush_24.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d220e5dfdac5aabafd837ab6d4374efe/df56e/shush_23.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADt0lEQVQ4y3WSXUxcRRSA75J02RB9UB9sTF8aExONL30wMf4kanzSKA+t8eehMWlqgsa0idHSoMWlsCW7FVBsi4ClgBTYZXdZELrSgiwiptSi9sEfkLZSWFpg987cO3funbkzc8xdSgP+nHw5mUzOd855OFpJSUkgECgOBIq98G9QXOy/nf1+f7HfHwgEtvn927aG9n/h8/mKijx8Pt+WL1/RndAOlx99a//B3Xv2Pvv8y489/tyju5548OFd23c8dO/9O++654G779uxfecjTz7zQjAYHh2ZyHw7lRmdmhibyhTQAAAUKA7CBlN3F66tXfn56sT4zGBy7MyX8UikORZN62vUq1IKFIAsoDw0ggRBghqKmsqxvBaK364QBGThbZvCyDumzjZw1tFsEzZDDWVhSZAkSFhYWEgaOiOIW9j9N/+UbRMcAjZZbwS2qf5DQ8LDkw21ZbKp9JvUQtKhXhdqgDd/k0kK2cScYK7ZhtwsW0jqWYvkXaQzPec4BEhBIBsaRa6BGUfSxkKz15e8syqWli64DfOX/pz/cU5wsJBLsQfBLjZ4znAAQdKenSbLGtEF0aWFPEhekLxgBCiBdDQz3DNmIpklzkVrLWOvpOlyB5vvdOavmuab4twhPq7lbjLmgGXIhd8RXuGWLlwGf/x04919VXteeW/20o0GmCuVo6+qzOtycrcaLZXnX5LfPK2iT0GXZuRchyqC3NVFaqy6xhqXHCYv/LJvb/lrb3wwNnQ5o/Jd9vW4s9hPswl7cYAurWL+Dp/8kE178tIcxivcZWBbykLCoZC/Zb9dFiotLRtJXQQbZF4qDAqBQoojATqcs7JDdFEjSM5eXs0tOUZOEiwdU9kE0Ao7UBYqfXH/7MwCtSGXdxDmJhIGYhhxHTGGhYOk9kM6O31heWb8Via1dP03wohyiDc5VNVU/n549tcsY4B0ZqLCtSFuIG4hYWCOEdeMnDTz3p1IDox65ykY/HVt7cSprqamnu+/u2K5sIyoiQTBkpqSGMJErold2wItmRjuT6YHUiO93alEdKg/NpyKp7s6EofLa44GPzkePtXVnuhsj0e7B/p6B3u7k7HeZDI5GIv1N3/RptVFGmuqIpHahqojtdWV4Zpg5GRja2VFzcEDh4JHQhWHg7XVddVVkcixhtqa+vCxhnCovv54Y2VF9ccfVWvNjX1tJ1KtnyVPRmItn8Y7Wwb6vhpp/TwaDjU3hNvqalvPnh483dR3pjne3TaUPHu+p+3r3o6h9pZEvGfkbykff3JyKsQHAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Shush",
                "title": "Shush",
                "src": "/static/d220e5dfdac5aabafd837ab6d4374efe/5a190/shush_23.png",
                "srcSet": ["/static/d220e5dfdac5aabafd837ab6d4374efe/772e8/shush_23.png 200w", "/static/d220e5dfdac5aabafd837ab6d4374efe/e17e5/shush_23.png 400w", "/static/d220e5dfdac5aabafd837ab6d4374efe/5a190/shush_23.png 800w", "/static/d220e5dfdac5aabafd837ab6d4374efe/df56e/shush_23.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      